<template>
  <router-link v-if="media" :to="{ name: 'media-open', params: { id: media.id } }" class="media-card">
    <div class="media-card__shadow" />
    <ImgComponent :img="media.img" class="media-card__image" />
    <div class="media-card__content">
      <span v-if="media.title" class="media-card__title">{{ media.title }}</span>
      <div class="media-card__content-bottom">
        <span v-if="media.data" class="media-card__subtitle">{{ media.data | humanDate }} г.</span>
        <div v-if="media.attendance" class="media-card__count">
          <IconComponent name="eye" />
          <span class="media-card__subtitle">{{ media.attendance }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "MediaCardComponent",
  props: {
    media: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.media-card {
  max-height 350px
  border-radius 10px
  overflow hidden
  display flex
  align-items end
  position relative
  +below(700px) {
    height 250px
  }

  &__shadow {
    width 100%
    height 100%
    absolute left top
    background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%)
  }

  &__image {
    width 100%
    height 100%
  }

  &__content {
    position absolute
    padding 10px 15px
    display flex
    flex-direction column
    gap 10px
    +below(700px) {
      gap 5px
    }
  }

  &__title {
    font-size 1.25rem
    font-weight 600
    line-height 1.75rem
    color var(--white)
    +below(700px) {
      font-size 1.125rem
      line-height 1.625rem
    }
  }

  &__content-bottom {
    display flex
    align-items center
    gap 20px
  }

  &__subtitle {
    font-size .875rem
    line-height 1.5rem
    color var(--white_o8)
    +below(700px) {
      font-size .75rem
      line-height 1.375rem
    }
  }

  &__count {
    display flex
    align-items center
    gap 5px

    .icon svg {
      max-width 20px
      max-height 20px
    }
  }
}
</style>
