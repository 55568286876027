<template>
  <main class="media-page page">
    <BreadcrumbsComponent title="СМИ о нас" />
    <div class="container-padding">
      <div v-if="media && media.length" class="media-page__inner">
        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div v-if="!loading" class="media-page__content">
          <MediaCardComponent v-for="(media, i) in media" :key="i" :media="media" />
        </div>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="parseInt(total || '0')"
          @paginate="paginate"
        />
      </div>
      <h2 v-else class="no-info">В данный момент информация отсутствует</h2>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import MediaCardComponent from "components/MediaCardComponent.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import MEDIA_PAGE from "gql/pages/MediaPage.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "MediaPage",
  metaInfo: {
    title: "СМИ о нас",
  },
  async asyncData({ apollo, store }) {
    let variables = {
      page: 1,
      first: 6,
    };
    await apollo.defaultClient
      .query({
        query: MEDIA_PAGE,
        variables: variables,
      })
      .then(({ data }) => {
        store.dispatch("media/save", data);
      });
  },
  data() {
    return {
      page: 1,
      first: 6,
      loading: false,
    };
  },
  computed: {
    media() {
      return this.$store.state.media.media.data;
    },
    total() {
      return this.$store.state.media.media.paginatorInfo.total;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    fetch() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          page: this.page,
          first: this.first,
        };
        this.$apollo
          .query({
            query: MEDIA_PAGE,
            variables: variables,
          })
          .then(({ data }) => {
            this.$store.dispatch("media/save", data);
            this.loading = false;
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    PaginationComponent,
    MediaCardComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.media-page {
  &__inner {
    padding 50px 0 0 0
    display grid
    grid-gap 50px
  }

  &__content {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px 20px
    +below(900px) {
      grid-template-columns 1fr
    }
  }
}
</style>
